import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import 'lite-youtube-embed'
import 'lite-vimeo-embed/module/lite-vimeo-embed'
import 'virtual:svg-icons-register'

import.meta.glob('../img/**/*')

import { splide } from './splide.js'
import { initStore } from './store.js'

export function init() {
  window.APP = window.APP || {}
  window.APP.splide = splide

  window.Alpine = Alpine

  Alpine.plugin(focus)
  Alpine.plugin(collapse)
  
  initStore()
  
  Alpine.start()
}

window.addEventListener('DOMContentLoaded', init)

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
