import Alpine from 'alpinejs'

export const initStore = () => {

  Alpine.store('nav', {
    minYOffset: 50,
    mobileNavOpen: false,
    scrolledFromTop: false,
    dropdownOpen: null,

    handleScroll() {
      this.scrolledFromTop = window.scrollY >= this.minYOffset
    },

    init() {
      this.handleScroll()
    },

    toggleMobileNav() {
      this.mobileNavOpen = !this.mobileNavOpen
    },

    closeMobileNav() {
      this.mobileNavOpen = false
    },

    closeDropdown(elementToFocusAfter = null) {
      this.dropdownOpen = ''

      if (elementToFocusAfter) {
        elementToFocusAfter.focus()
      }
    },

    isDropdownOpen(id) {
      return this.dropdownOpen === id
    },

    toggleDropdown(id) {
      this.dropdownOpen = this.isDropdownOpen(id) ? null : id
    },

    handleDropdownItemTab(isLast = false) {
      if (isLast) {
        this.closeDropdown()
      }
    }
  })

  Alpine.store('searchModal', {
    open: false,
    toggle() {
      Alpine.store('nav').closeMobileNav()
      Alpine.store('nav').closeDropdown()
      this.open = !this.open
    },
    close() {
      this.open = false
    }
  })

  Alpine.store('videoModal', {
    open: '',

    isVideoModalOpen(id) {
      return this.open === id
    },

    toggle(id) {
      this.open = this.isVideoModalOpen(id) ? null : id
    },

    close() {
      this.open = ''
    }
  })
}
